import { authStore } from '@/shared/pinia-store/auth';


export const useOnboardingChallengeTimer = () => {
  const challengeEndsAt = () => {
    const { user } = authStore();


    const currentTime = new Date();
    const creationTimestamp = new Date(user.value.date_joined).getTime();
    const currentTimestamp = new Date(currentTime).getTime();
    const diff = 48 * 60 * 60 * 1000 - (currentTimestamp - creationTimestamp);
    return diff > 0 ? diff : 0;
  };

  return { challengeEndsAt };
};
